import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19} cy={19} r={19} fill="#000" className="svg_fill-black" />
      <path
        d="M28.877 13.845a2.584 2.584 0 00-1.818-1.818c-1.614-.442-8.07-.442-8.07-.442s-6.456 0-8.07.425a2.636 2.636 0 00-1.818 1.835c-.424 1.613-.424 4.96-.424 4.96s0 3.364.424 4.961c.238.884.935 1.58 1.818 1.818 1.631.442 8.07.442 8.07.442s6.456 0 8.07-.425a2.584 2.584 0 001.818-1.818c.425-1.614.425-4.96.425-4.96s.017-3.365-.425-4.978zm-11.943 8.052v-6.184l5.368 3.092-5.368 3.092z"
        fill="#fff"
        className="svg_fill-white"
      />
    </svg>
  );
}

export default SvgComponent;
