import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0C8.507 0 0 8.507 0 19s8.507 19 19 19 19-8.507 19-19S29.493 0 19 0zm-4.177 8.928c1.081-.049 1.426-.06 4.178-.06h-.003c2.753 0 3.097.011 4.178.06 1.079.05 1.816.22 2.462.471.667.259 1.23.605 1.794 1.169.564.563.91 1.128 1.17 1.795.249.644.42 1.38.47 2.46.05 1.08.062 1.425.062 4.178 0 2.752-.013 3.096-.061 4.177-.051 1.078-.222 1.815-.471 2.46a4.975 4.975 0 01-1.17 1.794 4.957 4.957 0 01-1.794 1.17c-.644.25-1.382.421-2.46.47-1.081.05-1.426.062-4.178.062s-3.097-.012-4.178-.061c-1.078-.05-1.815-.22-2.46-.471a4.961 4.961 0 01-1.794-1.17 4.965 4.965 0 01-1.169-1.795c-.25-.644-.421-1.38-.47-2.46-.05-1.08-.062-1.424-.062-4.176 0-2.753.013-3.098.061-4.179.049-1.078.22-1.815.47-2.46a4.974 4.974 0 011.17-1.794 4.961 4.961 0 011.796-1.169c.644-.25 1.38-.421 2.46-.47z"
        fill="#000"
        className="svg_fill-black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.093 10.693h.909c2.705 0 3.026.01 4.094.058.988.045 1.525.21 1.882.349.473.183.81.403 1.164.758.355.354.575.692.759 1.165.138.357.304.893.349 1.881.048 1.068.059 1.39.059 4.093 0 2.705-.01 3.026-.06 4.094-.045.988-.21 1.524-.348 1.881-.184.473-.404.81-.759 1.164a3.133 3.133 0 01-1.164.758c-.357.14-.894.304-1.882.35-1.068.048-1.389.058-4.094.058-2.706 0-3.027-.01-4.095-.059-.988-.045-1.524-.21-1.882-.35a3.138 3.138 0 01-1.165-.757 3.141 3.141 0 01-.758-1.165c-.14-.357-.305-.893-.35-1.88-.048-1.07-.058-1.39-.058-4.096 0-2.706.01-3.025.058-4.094.046-.988.21-1.524.35-1.881a3.14 3.14 0 01.758-1.165 3.145 3.145 0 011.165-.759c.357-.139.894-.304 1.882-.35.935-.041 1.297-.054 3.186-.056v.003zm6.317 1.683a1.216 1.216 0 100 2.432 1.216 1.216 0 000-2.432zM13.797 19a5.204 5.204 0 1110.408 0 5.204 5.204 0 01-10.408 0z"
        fill="#000"
        className="svg_fill-black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.001 15.623a3.378 3.378 0 110 6.755 3.378 3.378 0 010-6.755z"
        fill="#000"
        className="svg_fill-black"
      />
    </svg>
  );
}

export default SvgComponent;
