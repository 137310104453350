import React from "react"
import styled from "styled-components"
import QA from "./QA"
import { pageMargins } from "../../shared/styles"

const StyledFAQ = styled.div`
  ${pageMargins};
  padding-bottom: 4rem;
  .faq_question {
    width: 50%;
    min-width: 41rem;
    position: relative;
    border-bottom: 1px solid white;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 1rem 0 1.368rem 0;
    cursor: pointer;
    & p {
      margin-bottom: 0rem;
      font-weight: 600;
    }
    & svg {
      width: 1.3rem;
      transition: transform 0.3s ease;
      &.is-open {
        transform: rotate(180deg);
      }
    }
    & .is-open {
    }
  }
  @media only screen and (max-width: 1024px) {
    .faq_question {
      width: 100%;
      min-width: unset;
    }
  }
  & .faq_answer {
    overflow: hidden;
    height: 0;
    transition: opacity 0.4s ease;
    opacity: 0;
    margin-top: 0.3rem;
    &.is-open {
      height: auto;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 640px) {
    padding-bottom: 2rem;
  }
`

const FAQ = ({ dropdown, className }) => {
  return (
    <StyledFAQ className={className}>
      <h2>FAQs</h2>
      {dropdown.map(item => (
        <QA question={item.question.html} answer={item.answer.html} />
      ))}
    </StyledFAQ>
  )
}

export default FAQ
