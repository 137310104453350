import React from "react"

import Facebook from "../images/Facebook"
import Instagram from "../images/Instagram"
import Twitter from "../images/Twitter"
import Youtube from "../images/Youtube"

const Socials = ({ socials }) => (
  <React.Fragment>
    {socials.map((social, i) => {
      switch (social.social) {
        case "facebook":
          return (
            <a
              key={`social-${i}`}
              href={social.social_link.url}
              target="_blank"
            >
              <Facebook />
            </a>
          )
        case "instagram":
          return (
            <a
              key={`social-${i}`}
              href={social.social_link.url}
              target="_blank"
            >
              <Instagram />
            </a>
          )
        case "twitter":
          return (
            <a
              key={`social-${i}`}
              href={social.social_link.url}
              target="_blank"
            >
              <Twitter />
            </a>
          )
        case "youtube":
          return (
            <a
              key={`social-${i}`}
              href={social.social_link.url}
              target="_blank"
            >
              <Youtube />
            </a>
          )
        default:
          return null
      }
    })}
  </React.Fragment>
)

export default Socials
