import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0C8.507 0 0 8.507 0 19s8.507 19 19 19 19-8.507 19-19S29.493 0 19 0zm1.98 19.835v10.337h-4.277V19.835h-2.137v-3.562h2.137v-2.139c0-2.906 1.207-4.634 4.635-4.634h2.854v3.563h-1.784c-1.335 0-1.423.497-1.423 1.426l-.005 1.783h3.232l-.378 3.563H20.98z"
        fill="#000"
        className="svg_fill-black"
      />
    </svg>
  );
}

export default SvgComponent;
