import React from "react"
import htmlParser from "html-react-parser"
import styled from "styled-components"
import { remCalc, pageMargins, color } from "../shared/styles"
import Socials from "./Socials"

const StyledContainer = styled.div`
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  ${pageMargins};
  &.section_contact {
    padding-left: 0;
    padding-right: 0;
    & h2,
    p {
      ${pageMargins}
      color: ${color.bg};
    }
  }
  @media only screen and (max-width: 640px) {
    padding-top: ${remCalc(50)};
    padding-bottom: ${remCalc(50)};
  }
  & .social_container {
    ${pageMargins};
    & svg {
      & * {
        fill: ${color.bg};
      }
    }
  }
`

const GeneralSection = ({ html, className, socials }) => {
  return (
    <StyledContainer className={className}>
      {htmlParser(html)}
      {socials ? (
        <div className="social_container">
          <Socials socials={socials} />
        </div>
      ) : (
        ""
      )}
    </StyledContainer>
  )
}

export default GeneralSection
