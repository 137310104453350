import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19} cy={19} r={19} fill="#000" className="svg_fill-black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.708 15.78l.044.713-.732-.087c-2.666-.334-4.994-1.468-6.971-3.372l-.966-.945-.25.697c-.527 1.556-.19 3.198.909 4.303.585.61.453.698-.557.334-.351-.116-.659-.203-.688-.16-.103.102.249 1.425.527 1.948.38.727 1.157 1.44 2.006 1.86l.718.335-.85.015c-.82 0-.849.014-.761.32.293.944 1.45 1.947 2.738 2.383l.908.305-.79.465a8.352 8.352 0 01-3.925 1.076c-.659.015-1.2.073-1.2.116 0 .146 1.786.96 2.825 1.28 3.12.944 6.824.537 9.607-1.076 1.977-1.148 3.953-3.43 4.876-5.64.498-1.177.996-3.328.996-4.36 0-.67.044-.756.864-1.556.483-.465.937-.974 1.025-1.119.146-.276.132-.276-.615-.029-1.245.436-1.42.378-.806-.276.454-.465.996-1.308.996-1.556 0-.043-.22.03-.468.16-.264.146-.85.364-1.289.495l-.79.247-.718-.48c-.396-.262-.952-.552-1.245-.64-.747-.203-1.89-.174-2.563.059-1.83.654-2.987 2.34-2.855 4.186z"
        fill="#fff"
        className="svg_fill-white"
      />
    </svg>
  );
}

export default SvgComponent;
